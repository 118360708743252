import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../Constant";

export const quickBiteApi = createApi({
  reducerPath: "quickBiteApi",
  baseQuery: fetchBaseQuery({ baseUrl: Config.baseUrl }),
  endpoints: (builder) => ({
    getReferences: builder.query({
      query: () => Config.getReferences,
    }),
    getProducts: builder.query({
      query: (id) => `${Config.getProducts}/${id}`,
    }),
    getRestaurants: builder.query({
      query: () => Config.getRestaurantsDetail,
    }),
    addPostContactForm: builder.mutation({
      query: (data) => ({
        url: "query-send",
        method: "POST",
        body: data,
      }),
    })
  }),
});

export const {
  useGetReferencesQuery,
  useGetProductsQuery,
  useGetRestaurantsQuery,
  useAddPostContactFormMutation
} = quickBiteApi;
