import React, { useState } from "react";
import Snackbar from "../../Components/Snackbar/Snackbar";
import "./GetAQuote.css";
import { useAddPostContactFormMutation } from "../../quickBiteServices/quickBiteApi";
import { useTranslation } from "react-i18next";
import MetaTags from "../../Components/SEO/MetaTags";
const GetAQuote = () => {
  const { t } = useTranslation();
  const [addPostContactForm] = useAddPostContactFormMutation();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    business: "",
    phone: "",
    country: "",
    city: "",
    subject: "",
    message: "",
  });

  const description = `
  Wanna try Quick Bite? Start your 15-day free trial now. 
  Subscribe if you wish to continue using Quick Bite's Online Ordering System for your business.
  `

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.phone) {
      setMessage(t("GetAQoute.ValidateError"));
      setShowSnackbar(true);
      setError(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await addPostContactForm(formData);
        if (response.error) {
          console.log(response.error);
          setMessage(t("GetAQoute.ErrorInSubmiting"));
          setShowSnackbar(true);
          setError(true);
        } else {
          setFormData({
            name: "",
            email: "",
            business: "",
            phone: "",
            country: "",
            city: "",
            subject: "",
            message: "",
          });
          setMessage(t("GetAQoute.Success"));
          setShowSnackbar(true);
          setError(false);
        }
      } catch (error) {
        setMessage(t("GetAQoute.ErrorInSubmiting"));
        setShowSnackbar(true);
        setError(true);
      }
    }
  };

  // "GetAQoute": {
  //   "ValidateError": "Please fill the required fields",
  //   "ErrorInSubmiting": "Something went wrong. Please try again later.",
  //   "Success": "Form submitted successfully!",
  //   "Heading": "Wanna try quick bite",
  //   "Detail": "You can subscribe after 15 days free period if you want to continue using Quick Bite Online Ordering System for your business.",
  //   "FullName": "Full Name",
  //   "Email": "Email",
  //   "BusinessName": "Business Name",
  //   "Phone": "Phone",
  //   "Country": "Country",
  //   "City": "City",
  //   "BusinessType": "Business Type",
  //   "Note": "Any special notes ?",
  //   "Submit": "Submit"
  //   },

  return (
    <div className="trail__page">
      <MetaTags href={"quote"} pageName={"quote"} content={description} metaTitle={"Experience Quick Bite: Start Your 15-Day Free Trial Today!"} />

      <Snackbar
        setShowSnackbar={setShowSnackbar}
        showSnackbar={showSnackbar}
        message={message}
        error={error}
      />
      <div className="quote__content">
        <h1 className="heading">{t("GetAQoute.Heading")}</h1>

        <p className="paragraph">
          {t("GetAQoute.Detail")}
        </p>
      </div>

      <div className="quote__form">
        <div className="input__field">
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
          />

          <label htmlFor="name">
            {t("GetAQoute.FullName")}
            <span className="required__filed">*</span>
          </label>
        </div>

        <div className="input__field">
          <input
            type="text"
            id="email"
            value={formData.email}
            onChange={handleChange}
          />

          <label htmlFor="email">
            {t("GetAQoute.Email")}
            <span className="required__filed">*</span>
          </label>
        </div>

        <div className="input__field">
          <input
            type="text"
            id="business"
            value={formData.business}
            onChange={handleChange}
          />

          <label htmlFor="business">
            {t("GetAQoute.BusinessName")}
            {/* <span className="required__filed">*</span> */}
          </label>
        </div>

        <div className="input__field">
          <input
            type="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
          />

          <label htmlFor="phone">{t("GetAQoute.Phone")}<span className="required__filed">*</span></label>
        </div>

        <div className="input__field">
          <input
            type="text"
            id="country"
            value={formData.country}
            onChange={handleChange}
          />

          <label htmlFor="country">{t("GetAQoute.Country")}
            {/* <span className="required__filed">*</span> */}
          </label>
        </div>

        <div className="input__field">
          <input
            type="text"
            id="city"
            value={formData.city}
            onChange={handleChange}
          />

          <label htmlFor="city">{t("GetAQoute.City")}
            {/* <span className="required__filed">*</span> */}
          </label>
        </div>

        <div className="input__field full__width">
          <input
            type="text"
            id="subject"
            value={formData.subject}
            onChange={handleChange}
          />

          <label htmlFor="subject">{t("GetAQoute.BusinessType")}
            {/* <span className="required__filed">*</span> */}
          </label>
        </div>

        <div className="input__field textarea full__width">
          <input
            type="text"
            id="message"
            value={formData.message}
            onChange={handleChange}
          />

          <label htmlFor="message">{t("GetAQoute.Note")}</label>
        </div>

        <button className="btn" onClick={handleSubmit}>
          {t("GetAQoute.Submit")}
        </button>
      </div>

      <div className="contact__detail">

        <h1 className="heading">
          Kontakt os og få et uforpligtende tilbud
        </h1>

        <div className="info__div">
          <span>
            <span>
              Slag :-
            </span>
            <a className={`link`} href="tel:004552635204">+45 52635204</a>
          </span>
          <span>
            <span>
              Support :-
            </span>
            <a className={`link`} href="tel:004552635204">+45 52635204</a>
          </span>

        </div>
      </div>
    </div>
  );
};

export default GetAQuote;
