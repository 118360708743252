import React from "react";
import "./BusinessType.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MetaTags from "../../Components/SEO/MetaTags";

const BusinessType = () => {
  const { t } = useTranslation();

  const description = `
  Discover personalized online ordering solutions tailored for different business types, commission-free. 
  Whether it's a special occasion or a casual meal, join us at Restaurant Name for a memorable dining experience.
  `
  return (
    <div className="bussiness__container">
      <MetaTags href={"businessType"} pageName={"businessType"} content={description} metaTitle={"Tailored Online Ordering: Commission-Free Solutions for Your Business"} />

      <div className="container__header">
        <h1 className="heading">{t("BusinessType.Heading")}</h1>
        <p className="paragraph">
          {t("BusinessType.Detail")}
        </p>
      </div>
      <div className="bussiness__cards">
        <Link to="/bakery" className="bussiness__card">
          <img src="images/bussinessType/backery.jpg" alt="" />
          <div className="card__content">
            <h3 className="content__heading">{t("BusinessType.Bakery")}</h3>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </Link>
        <Link to="/cafe" className="bussiness__card">
          <img src="images/bussinessType/cafe.jpg" alt="" />
          <div className="card__content">
            <h3 className="content__heading">{t("BusinessType.Cafe")}</h3>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </Link>
        <Link to="/foodtruck" className="bussiness__card">
          <img src="images/bussinessType/foodtruck.jpg" alt="" />
          <div className="card__content">
            <h3 className="content__heading">{t("BusinessType.FoodTruck")}</h3>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </Link>
        <Link to="/restaurant" className="bussiness__card">
          <img src="images/bussinessType/food.jpg" alt="" />
          <div className="card__content">
            <h3 className="content__heading">{t("BusinessType.Reastaurants")}</h3>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </Link>
        <Link to="/hotel" className="bussiness__card">
          <img src="images/bussinessType/hotel.jpg" alt="" />
          <div className="card__content">
            <h3 className="content__heading">{t("BusinessType.Hotel")}</h3>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </Link>
        <Link to="/beach" className="bussiness__card">
          <img src="images/bussinessType/beach.jpg" alt="" />
          <div className="card__content">
            <h3 className="content__heading">{t("BusinessType.Beach")}</h3>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </Link>
        <Link to="/grocery" className="bussiness__card">
          <img src="images/bussinessType/grocery.jpg" alt="" />
          <div className="card__content">
            <h3 className="content__heading">{t("BusinessType.Grocery")}</h3>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BusinessType;