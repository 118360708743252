import React from "react";
import ServiceCard from "../../Pages/OnlineOrdering/ServiceCard/ServiceCard";
import { useTranslation } from "react-i18next";

function ServiceCards({ type= "1" }) {
    const { t } = useTranslation();

    return (
        <>
            {type === 1 ? (
                <div className="service__cards">
                    <ServiceCard
                        Icon={<i className="ri-truck-line"></i>}
                        CardHeading={t("ServiceCards.1Heading")}
                        CardParagraph={t("ServiceCards.1Detail")}
                    />

                    <ServiceCard
                        Icon={<i className="ri-shopping-bag-3-line"></i>}
                        CardHeading={t("ServiceCards.2Heading")}
                        CardParagraph={t("ServiceCards.2Detail")}
                    />

                    <ServiceCard
                        Icon={<i className="ri-qr-code-line"></i>}
                        CardHeading={t("ServiceCards.3Heading")}
                        CardParagraph={t("ServiceCards.3Detail")}
                    />

                    {/* <ServiceCard
                        Icon={<i className="ri-phone-fill"></i>}
                        CardHeading={t("ServiceCards.4Heading")}
                        CardParagraph={t("ServiceCards.4Detail")}
                    /> */}
                </div>
            ) : type === 2 ? (
                <div className="service__cards">
                    <ServiceCard
                        Icon={<i className="ri-smartphone-line"></i>}
                        CardHeading={t("ServiceCards.5Heading")}
                        CardParagraph={t("ServiceCards.5Detail")}
                    />

                    <ServiceCard
                        Icon={<i className="ri-pie-chart-line"></i>}
                        CardHeading={t("ServiceCards.6Heading")}
                        CardParagraph={t("ServiceCards.6Detail")}
                    />

                    <ServiceCard
                        Icon={<i className="ri-group-line"></i>}
                        CardHeading={t("ServiceCards.7Heading")}
                        CardParagraph={t("ServiceCards.7Detail")}
                    />

                    <ServiceCard
                        Icon={<i className="ri-emoji-sticker-line"></i>}
                        CardHeading={t("ServiceCards.8Heading")}
                        CardParagraph={t("ServiceCards.8Detail")}
                    />
                </div>
            ) : type === 3 ? (
                <div className="service__cards">
                    <ServiceCard
                        Icon={<i className="ri-article-line"></i>}
                        CardHeading={t("ServiceCards.9Heading")}
                        CardParagraph={t("ServiceCards.9Detail")}
                    />

                    <ServiceCard
                        Icon={<i className="ri-notification-line"></i>}
                        CardHeading={t("ServiceCards.10Heading")}
                        CardParagraph={t("ServiceCards.10Detail")}
                    />

                    <ServiceCard
                        Icon={<i className="ri-error-warning-line"></i>}
                        CardHeading={t("ServiceCards.11Heading")}
                        CardParagraph={t("ServiceCards.11Detail")}
                    />

                    {/* <ServiceCard
                        Icon={<i className="ri-phone-line"></i>}
                        CardHeading={t("ServiceCards.12Heading")}
                        CardParagraph={t("ServiceCards.12Detail")}
                    /> */}
                </div>
            ) : (
                <div>{t("ServiceCards.NotFound")}</div>
            )}
        </>
    );
}

export default ServiceCards;
