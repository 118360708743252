import React from 'react'
import ServiceCard from '../../OnlineOrdering/ServiceCard/ServiceCard'
import ServiceCards from '../../../Components/ServiceCards/ServiceCards'
import { useTranslation } from 'react-i18next';
import MetaTags from '../../../Components/SEO/MetaTags';

const Grocery = () => {
    const { t } = useTranslation();

    const description = `
    Empower your grocery business with customizable delivery times. Let customers select date and time ranges for their orders while defining order limits. 
    Enjoy exclusive offers on our website without commission fees to other platforms.
    `

    // "Grocery": {
    //     "1Heading": "Experience the sweetness of convenience with our online donut ordering system!",
    //     "1Detail": "Your customers ca order online from your website and enjoy yuor offers. You won’t have to pay any commissions to other platforms.",
    //     "2Heading: ": "Time ranges & order limits for delivery orders",
    //     "2Detail": "Let your customers pick a date and time range for their grocery orders. Define orders limit for each range and easily plan your daily route.",
    //     "2Link": "All Ordering Types",
    //     "3Heading1": "Receive and confirm orders",
    //     "3Heading2": "with any device",
    //     "3Detail": "You can use any PC, Mac, tablet or notebook to accept online orders. Moreover, you can even use your own smart phone to start taking orders.",
    //     "3Link": "It's so Easy to Manage"
    //   },
    return (
        <>
            <MetaTags href={"grocery"} pageName={"grocery"} content={description} metaTitle={"Effortless Grocery Ordering: Customize Delivery Times with Ease!"} />

            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Grocery.1Heading")}
                    </h1>

                    <p className="paragraph">
                        {t("Grocery.1Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/grocery.png" alt="" />

            </div>


            <ServiceCards type={1} />

            <div className="service__container reverse__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Grocery.2Heading")}
                    </h1>

                    <p className="paragraph">
                        {t("Grocery.2Detail")}
                    </p>

                    <a className="simple__link paragraph">
                        {t("Grocery.2Link")}
                    </a>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/qr.png" alt="" />

            </div>


            <ServiceCards type={2} />

            <div className="service__container">

                <div className="service__content">

                    <a className="simple__link paragraph">
                        {t("Grocery.3Link")}
                    </a>

                    <h1 className="heading">
                        {t("Grocery.3Heading1")}
                        <span>
                            &nbsp; {t("Grocery.3Heading2")}
                        </span>
                    </h1>

                    <p className="paragraph">
                        {t("Grocery.3Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />

            </div>

            <ServiceCards type={3} />

        </>
    )
}

export default Grocery