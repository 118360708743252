import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import { getLocalStorage } from "../local_storage";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import CoffeeMakerOutlinedIcon from "@mui/icons-material/CoffeeMakerOutlined";
import BreakfastDiningOutlinedIcon from "@mui/icons-material/BreakfastDiningOutlined";
import LocalHotelOutlinedIcon from "@mui/icons-material/LocalHotelOutlined";
import SurfingOutlinedIcon from "@mui/icons-material/SurfingOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";


const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("/");
  const [navbar, setNavbar] = useState();
  const [homeNavbar, setHomeNavbar] = useState(true);
  const [activeLanguage, setActiveLanguage] = useState(
    getLocalStorage("i18nextLng")
  );
  const toggleNavbar = () => {
    setNavbar(!navbar);
  };
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  useEffect(() => {
    setNavbar(false);
  }, [currentPath]);

  useEffect(() => {
    setNavbar(false);
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;
      if (scrollPosition > scrollThreshold) {
        setHomeNavbar(false);
      } else {
        setHomeNavbar(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function handleClick(lang) {
    i18next.changeLanguage(lang);
    setActiveLanguage(lang);
  }
  return (
    // <div
    //   className={`nav__bar ${homeNavbar && currentPath === "/" ? "home__navbar" : ""
    //     }`}
    // >
    <div
      className={`nav__bar`}
    >
      <div className="nav__logo">

        <Link
          to="/"
        >
           <img  className="logo_img" src="./Images/logo_1.png" alt="" />
        </Link>

        <div className="lang__toggler">
          <i
            class="ri-earth-line"
            style={{ color: "var(--primary-color)" }}
          ></i>

          <span className="log__text" style={{ color: "var(--primary-color)" }}>
            {activeLanguage === "dn" ? "Danish" : "English"}
          </span>

          <div className="toggle__popup">
            <div className="lang__li" onClick={() => handleClick("en")}>
              <span
                className={`log__text ${activeLanguage === "en" && "active__lang"
                  }`}
              >
                English
              </span>
            </div>

            <div className="lang__li" onClick={() => handleClick("dn")}>
              <span
                className={`log__text ${activeLanguage === "dn" && "active__lang"
                  }`}
              >
                Danish
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={`nav__links ${navbar && "active__navbar"}`}>
        <Link
          className={`link${currentPath === "/" ? " active__link" : ""}`}
          to="/"
        >
          {t("Navbar.Home")}
        </Link>

        <Link
          className={`link${currentPath === "/onlineordering" ? " active__link" : ""
            }`}
          to="/onlineordering"
        >
          {t("Navbar.OnlineOrdering")}
        </Link>

        <span
          className={`relative__link link${currentPath === "/businesstype" ? " active__link" : ""
            }`}
        >
          {t("Navbar.BusinessType")}
          <ArrowDropDownOutlinedIcon />
          <div className="nav__drop">
            <span className="link link__heading">{t("Navbar.FoodBeverages")}</span>

            <span className="link link__heading">{t("Navbar.Retail")}</span>

            <Link to="/foodtruck" className="link">
              <FastfoodOutlinedIcon />
              {t("Navbar.FoodTrucks")}
            </Link>

            <Link to="/grocery" className="link">
              <StorefrontOutlinedIcon />
              {t("Navbar.Grocery")}
            </Link>

            <Link to="/restaurant" className="link">
              <RestaurantOutlinedIcon />
              {t("Navbar.Restaurants")}
            </Link>

            <Link to="/beach" className="link">
              <SurfingOutlinedIcon />
              {t("Navbar.Beaches")}
            </Link>

            <Link to="/bakery" className="link">
              <BreakfastDiningOutlinedIcon />
              {t("Navbar.Bakaries")}
            </Link>

            <Link to="/hotel" className="link">
              <LocalHotelOutlinedIcon />
              {t("Navbar.Hotels")}
            </Link>

            <Link to="/cafe" className="link">
              <CoffeeMakerOutlinedIcon />
              {t('Navbar.Cafes')}
            </Link>

            <Link to="/businesstype" className="link">
              <WorkspacesOutlinedIcon />
              {t('Navbar.AllBussinesses')}
            </Link>
          </div>
        </span>

        <Link
          className={`link${currentPath === "/pricing" ? " active__link" : ""}`}
          to="/pricing"
        >
          {t("Navbar.Pricing")}
        </Link>

        <Link
          className={`link btn__link ${currentPath === "/quote" ? " active__link" : ""
            }`}
          to="/quote"
        >
          {t("Navbar.FreeTrail")}
        </Link>

        <a
          target="_blank"
          className={`link`}
          href="tel:004552635204"
        >
          <i class="ri-headphone-fill"></i>
          &nbsp; +45 52635204
        </a>

      </div>

      <i className="ri-menu-4-line menu__icon " onClick={toggleNavbar}></i>
    </div>
  );
};

export default Navbar;
