import React from 'react'
import ServiceCards from '../../../Components/ServiceCards/ServiceCards'
import { useTranslation } from 'react-i18next';
import MetaTags from '../../../Components/SEO/MetaTags';

const Cafe = () => {
    const { t } = useTranslation();

    const description = `
    Escape the queues and savor the perfect brew with our café's online ordering system. 
    Customers can order from their smartphones or opt for hassle-free delivery, enhancing their café experience.
    `

    return (
        <>
        
            <MetaTags href={"cafe"} pageName={"cafe"} content={description} metaTitle={"Unlock Delight: Embrace Convenience with Our Café's Online Ordering System!"} />

            <div className="service__container">
                <div className="service__content">
                    <h1 className="heading">
                        {t("Cafe.1Heading")}
                    </h1>
                    <p className="paragraph">
                        {t("Cafe.1Detail")}
                    </p>
                </div>
                <img className="service__img img__anime" src="images/bussinessType/bussinessess/coffee.png" alt="" />
            </div>
            <ServiceCards type={1} />
            <div className="service__container reverse__container">
                <div className="service__content">
                    <a className="simple__link paragraph">
                        {t("Cafe.2Link")}
                    </a>
                    <h1 className="heading">
                        {t("Cafe.2Heading1")}
                        <span>
                            &nbsp; {t("Cafe.2Heading2")}
                        </span>
                    </h1>
                    <p className="paragraph">
                        {t("Cafe.2Detail")}
                    </p>
                </div>
                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />
            </div>
            <ServiceCards type={2} />
        </>
    )
}

export default Cafe