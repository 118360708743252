import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { quickBiteApi } from '../quickBiteServices/quickBiteApi';

export const store = configureStore({
  reducer: {
    [quickBiteApi.reducerPath]: quickBiteApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(quickBiteApi.middleware),
});

setupListeners(store.dispatch);
