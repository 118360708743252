import React from 'react'

import './ServiceCard.css'

const ServiceCard = ({CardParagraph, CardHeading, Icon}) => {
    return (
        <div className='service__card'>

            {Icon}

            <span className="card__heading">
                {CardHeading}
            </span>

            <p className="paragraph">
                {CardParagraph}
            </p>

        </div>
    )
}

export default ServiceCard;