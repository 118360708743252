import React from 'react'

// import './products.css'
import { useTranslation } from 'react-i18next';
import productsData from './data.json';
import Card from './card';

const Products = () => {
  const { t } = useTranslation();

  return (
    <div className='payment__page'>

        {/* <div className="pricing__content">

          <h1 className="heading">
            {t("Pricing.Heading")}
          </h1>

          <p className="paragraph">
            {t("Pricing.note")}
          </p>

        </div> */}

    {/* {productsData} */}
    {/* {JSON.stringify(productsData)} */}
      <div className="pricing__plan">
        <div className="plan__containers">
            {productsData.map((item) => (
                <Card item={item} />
            ))}
          </div>
      </div>



    </div>
  )
}

export default Products
