import React from "react";

import ServiceCard from "./ServiceCard/ServiceCard";

import "./OnlineOrdering.css";
import { useTranslation } from "react-i18next";
import MetaTags from "../../Components/SEO/MetaTags";

const OnlineOrdering = () => {
  const { t } = useTranslation()

  const description = `
  Discover the convenience of Quik Bite's online ordering platform.
  Order from your favorite restaurants with ease and enjoy fast takeaway delivery!
  `

  // "OnlineOrdering": {
  //   "OrderingType1Heading": "Offer all types of online ordering to customers",
  //   "OrderingType1Detail": "Your customers can place orders for delivery, pickup (click & collect) or even dine-in from their tables. You only need one system for all ordering types.",
  //   "LearnMore1": "Discover All Online Ordering Type",
  //   "OrderingType1Service1Heading": "For Delivery",
  //   "OrderingType1Service1Detail": "Receive delivery orders from your customers",
  //   "OrderingType1Service2Heading": "For Pickup",
  //   "OrderingType1Service2Detail": "Your customers can order and pickup when ready",
  //   "OrderingType1Service3Heading": "For Dine-in",
  //   "OrderingType1Service3Detail": "Your customers can order from dine-in",
  //   "OrderingType1Service4Heading": "From Phone",
  //   "OrderingType1Service4Detail": "You can create manual orders with caller-id",
  //   "OrderingType2Heading1": "Receive and confirm orders ",
  //   "OrderingType2Heading2": "with any device",
  //   "OrderingType2Detail": "You can use any PC, Mac, tablet or notebook to accept online orders. Moreover, you can even use your own smart phone to start taking orders.",
  //   "LearnMore2": "Discover Order Taking Features",
  //   "OrderingType2Service1Heading": "All Devices",
  //   "OrderingType2Service1Detail": "Manage easily with any PC, Mac, phone or tablet",
  //   "OrderingType2Service2Heading": "Detailed Reports",
  //   "OrderingType2Service2Detail": "All reports and insights you will ever need",
  //   "OrderingType2Service3Heading": "Multi User",
  //   "OrderingType2Service3Detail": "Create users and roles for your staff",
  //   "OrderingType2Service4Heading": "User Friendly",
  //   "OrderingType2Service4Detail": "So easy to use and learn on first use",
  //   "OrderingType3Heading1": "Easily manage",
  //   "OrderingType3Heading2": " your Online Ordering System",
  //   "OrderingType3Detail": "Easy to use advanced management tools with detailed reports for growing your online orders with more accurate decisions.",
  //   "LearnMore3": "Discover QuickBite Dashboard",
  //   "OrderingType3Service1Heading": "Cloud Print",
  //   "OrderingType3Service1Detail": "Print order details easily by wireless printer",
  //   "OrderingType3Service2Heading": "Order Notifications",
  //   "OrderingType3Service2Detail": "Notify customer when preparing or on the way",
  //   "OrderingType3Service3Heading": "Get Notified",
  //   "OrderingType3Service3Detail": "QuickBite warns you if you have awaiting order",
  //   "OrderingType3Service4Heading": "Caller-ID",
  //   "OrderingType3Service4Detail": "Create orders manually by selecting customers"
  // }
  return (
    <>
      <MetaTags href={"onlineordering"} pageName={"Online Ordering"} content={description} metaTitle={"Convenient Online Ordering for Fast Takeaway Delivery"} />

      <div className="service__container reverse__container">
        <div className="service__content">
          <h1 className="heading">
            {t("OnlineOrdering.OrderingType1Heading")}
          </h1>

          <p className="paragraph">
            {t("OnlineOrdering.OrderingType1Detail")}
          </p>

          <a className="simple__link paragraph">{t("OnlineOrdering.LearnMore1")}</a>
        </div>

        <img className="service__img img__anime" src="images/home/onlineordering.png" alt="" />
      </div>

      <div className="service__cards">

        <ServiceCard
          Icon={<i class="ri-truck-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType1Service1Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType1Service1Detail")}
        />

        <ServiceCard
          Icon={<i class="ri-shopping-bag-3-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType1Service2Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType1Service2Detail")}
        />

        <ServiceCard
          Icon={<i class="ri-qr-code-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType1Service3Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType1Service3Detail")}
        />

        {/* <ServiceCard
          Icon={<i class="ri-phone-fill"></i>}
          CardHeading={t("OnlineOrdering.OrderingType1Service4Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType1Service4Detail")}
        /> */}

      </div>

      <div className="service__container">
        <div className="service__content">
          <h1 className="heading">
            {t("OnlineOrdering.OrderingType2Heading1")}<span>{t("OnlineOrdering.OrderingType2Heading2")}</span>
          </h1>

          <p className="paragraph">
            {t("OnlineOrdering.OrderingType2Detail")}
          </p>

          <a className="simple__link paragraph">{t("OnlineOrdering.LearnMore2")}</a>
        </div>

        <img className="service__img img__anime" src="images/home/onlineordering.png" alt="" />
      </div>

      <div className="service__cards">

        <ServiceCard
          Icon={<i class="ri-smartphone-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType2Service1Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType2Service1Detail")}
        />

        <ServiceCard
          Icon={<i class="ri-pie-chart-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType2Service2Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType2Service2Detail")}
        />

        <ServiceCard
          Icon={<i class="ri-group-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType2Service3Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType2Service3Detail")}
        />

        <ServiceCard
          Icon={<i class="ri-emoji-sticker-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType2Service4Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType2Service4Detail")}
        />
      </div>

      <div className="service__container reverse__container">
        <div className="service__content">
          <h1 className="heading">
            <span>{t("OnlineOrdering.OrderingType3Heading1")}</span>{t("OnlineOrdering.OrderingType3Heading2")}
          </h1>

          <p className="paragraph">
            {t("OnlineOrdering.OrderingType3Detail")}
          </p>

          <a className="simple__link paragraph">{t("OnlineOrdering.LearnMore3")}</a>
        </div>

        <img className="service__img img__anime" src="images/home/onlineordering.png" alt="" />
      </div>

      <div className="service__cards">
        <ServiceCard
          Icon={<i class="ri-article-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType3Service1Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType3Service1Detail")}
        />

        <ServiceCard
          Icon={<i class="ri-notification-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType3Service2Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType3Service2Detail")}
        />

        <ServiceCard
          Icon={<i class="ri-error-warning-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType3Service3Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType3Service3Detail")}
        />

        {/* <ServiceCard
          Icon={<i class="ri-phone-line"></i>}
          CardHeading={t("OnlineOrdering.OrderingType3Service4Heading")}
          CardParagraph={t("OnlineOrdering.OrderingType3Service4Detail")}
        /> */}

      </div>
    </>
  );
};

export default OnlineOrdering;
