import React from 'react'

import './Footer.css'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className='footer__section'>

        <div className="footer__content">

          <img src="images/logo_new.png" alt="" />

          <p className="paragraph">
            {t("Footer.Detail")}
          </p>

        </div>

        <div className="footer__links">

          <span className="link__heading">
            {t("Footer.SocialLinks")}
          </span>

          <div className="social__links">

            <a className="link">
              <span>
                Phone:
              </span>
              <a href="tel:004552635204">+45 52635204</a>
            </a>

          </div>

        </div>

      </div>

      <div className="footer__copy__container">
        <div>
          <span className="footer__copy">
            {t("Footer.Copyright")}&nbsp;
            <span>
              {t("Footer.QuickBite")}
            </span>
          </span>
        </div>
        <div className="footer__powered__by">
            {t("Footer.poweredBy")}&nbsp;
            <a target='blank' href="https://danzeetech.dk">DanZee Tech</a>
            
        </div>

      </div>
    </>
  )
}

export default Footer