import React from 'react'
import ServiceCard from '../../OnlineOrdering/ServiceCard/ServiceCard'
import ServiceCards from "../../../Components/ServiceCards/ServiceCards"
import { useTranslation } from 'react-i18next';
import MetaTags from '../../../Components/SEO/MetaTags';
const FoodTruck = () => {
    const { t } = useTranslation();
    const description = `
    Elevate your fast food restaurant with RestApp's enhanced Online Ordering system. 
    Receive commission-free delivery and pickup orders now!
    `
    return (
        <>
            <MetaTags href={"foodtruck"} pageName={"food Truck"} content={description} metaTitle={"Start Taking Commission-Free Online Orders Today with RestApp"} />

            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("FoodTruck.1Heading")}
                    </h1>

                    <p className="paragraph">
                        {t("FoodTruck.1Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/qr.png" alt="" />

            </div>


            <ServiceCards type={1} />

            <div className="service__container reverse__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("FoodTruck.2Heading")}
                    </h1>

                    <p className="paragraph">
                        {t("FoodTruck.2Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/qr.png" alt="" />

            </div>


            <ServiceCards type={2} />

            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("FoodTruck.3Heading1")}
                        <span>
                            &nbsp; {t("FoodTruck.3Heading2")}
                        </span>
                    </h1>

                    <p className="paragraph">
                        {t("FoodTruck.3Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />

            </div>

            <ServiceCards type={3} />
        </>
    )
}

export default FoodTruck