import React from 'react'

import './Features.css'
import { useTranslation } from 'react-i18next'

const Feature = () => {
  const { t } = useTranslation()
  return (
    <div id='features__container' className='features__container'>

      <div className='service__card'>

        <i class="fa-solid fa-burger"></i>

        <span className="card__heading">
          {t("Features.heading1")}
        </span>

        <p className="paragraph">
          {t("Features.Detail1")}
        </p>

      </div>

      <div className='service__card'>

        <i class="fa-solid fa-hand-holding-dollar"></i>
        
        <span className="card__heading">
        {t("Features.heading2")}
        </span>

        <p className="paragraph">
          {t("Features.Detail2")}
        </p>

      </div>

      <div className='service__card'>

        <i class="ri-bank-card-line"></i>

        <span className="card__heading">
        {t("Features.heading3")}
        </span>

        <p className="paragraph">
          {t("Features.Detail3")}
        </p>

      </div>

    </div>
  )
}

export default Feature
